
import jquery from 'jquery';
import { createApp, } from "vue";
import Vue3TouchEvents from 'vue3-touch-events';
import App from './App.vue';
import router from './router';
import store from './store';

window.$ = window.jQuery = require('jquery');

createApp(App).use(store).use(router).use(jquery).use(Vue3TouchEvents).mount('#app');
