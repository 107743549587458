<template>
 <div id="preloader">
    <div style="display: contents;" >
        <img src="../../../public/log.svg" alt="" style="position: absolute;">
            <div class='loader'></div>
      </div>
    </div>
<section id="docs">
    <h1>Документы</h1>
    <div class="ofer">
        <h3 >Договор Оферты</h3>
        <div class="doc_off" >
            <a href="https://nextcloud.domofon-37.ru/index.php/s/R9nzt6HeWBd7tyX" target="_blank">Договор-Оферта аренды и Абонентского обслуживания
и пользования СОД</a>
            <a href="https://nextcloud.domofon-37.ru/index.php/s/syrgmfnyrXdf4Bb" target="_blank">Договор-Оферта по Абонентскому обслуживанию
и пользованию СОД</a>
                <!-- <a href="https://nextcloud.domofon-37.ru/index.php/s/9MMi3Cnb8GpQGgW" target="_blank">Договор-Оферта о предоставлении услуг доступа к интернету</a> -->
        </div>
    </div>
    <div class="instruct">
        <h3 >Инструкция</h3>
        <div class="doc_inst">
        <a href="https://nextcloud.domofon-37.ru/index.php/s/oq22yPbsazsyZHZ" target="_blank">Подключение к xmeye pro</a>
        </div>
    </div>
    
   
</section>


<footer>
<Footer></Footer>
    </footer>
</template>
<script>
import Footer from '@/components/Footer.vue'

export default{
  components:{
    Footer,
},
mounted(){
    $(document).ready(function() { 
        document.body.classList.add('modal_open');
          let preloader = document.getElementById('preloader');
          
          setTimeout(function(){
            preloader.classList.add('hide-preloader');
            setInterval(function() {
                  preloader.classList.add('preloader-hidden');
                  document.body.classList.remove('modal_open');
            },3000);},2000)
        });
},
}
</script>