<template>
    <div class="form_modal" >
        <div class="modal">
            <a class="close_modal"
            @click="$emit('close',)">
                <img style="background: var(--close-modal);" alt="">
            </a>
            <h2>
                Заполните, пожалуйста, эту форму, спасибо
            </h2>
            <h3>
                * Обязательно к заполнению
            </h3>
            <form action="" class="form" id="form" @submit.prevent="createPost"   >
                <div class="fio">
                <div class="surname">
                    <fieldset>
                    <label><strong>Фамилия </strong><span>*</span></label>
                    <input type="text" placeholder="Иванов " id="surname" v-model="state.lastname">
                    
                    <span
                class="red-error"
                v-if="v$.lastname.$error &&! v$.lastname.$required "
                >Поле должно быть заполнено</span>
            </fieldset>
                </div>
            
            <div class="name">
                <fieldset >
                    <label><strong>Имя </strong><span>*</span></label>
                <input type="text" placeholder="Иван " id="name" v-model="state.firstname">
                <span
                class="red-error"
                v-if="v$.firstname.$error &&! v$.firstname.$required "
                >Поле должно быть заполнено</span>
            </fieldset>
            </div>
            
            
            <div class="patrony">
                <fieldset>
                <label ><strong>Отчество</strong></label>
                <input type="text" placeholder="Иванович " id="patronymic" v-model="state.middlename">


            </fieldset>
            </div>
        </div>
            
            
        
        <div>
            <fieldset>
                <label><strong>Номер телефона </strong><span>*</span></label>
            <input 
            type="tel" 
            autocomplete="tel"
            placeholder="8(555) 555-5555"
            id="phone"
            v-model="state.phone"

            >
            <span
                class="red-error"
                v-if="v$.phone.$error &&! v$.phone.$required "
                >Поле должно быть заполнено</span>
            </fieldset>
        </div>
        <div>
            <fieldset>
            <label><strong>Электронная почта</strong><span>*</span></label>
            <input type="email" placeholder="пример@домен.com" id="mail" v-model="state.email">
            
            <span
                class="red-error"
                v-if="v$.email.$error &&! v$.email.$required"
                >Поле должно быть заполнено</span>
            </fieldset>
        </div>
        <div>
            <fieldset>
            <label><strong>Адрес проживания</strong><span>*</span></label>
            <input type="text" placeholder="Иваново улица Жиделева, 21" id="address" v-model="state.address">
            
            <span
                class="red-error"
                v-if="v$.address.$error &&! v$.address.$required"
                >Поле должно быть заполнено</span>
            </fieldset>
        </div>

        <div class="message">
            <label><strong>Сообщение</strong><span>*</span></label>
            
            <textarea type="text"  id="massage" v-model="state.message">
           </textarea>
           
                    <span
                class="red-error"
                v-if="v$.message.$error &&! v$.message.$required "
                >Поле должно быть заполнено</span>
        </div>
        <div class="ok">
            
            <input type="checkbox" class="Check" v-model="state.check">
            
            
            <p>Подтверждаю своё согласие на обработку <br>
                <a href="https://nextcloud.domofon-37.ru/index.php/s/PQpRA36tHY4CfYG" target="_blank">персональных данных</a></p>
            
        </div>
        <span
                class="red-error"
                v-if="v$.check.$error &&! v$.check.$required  "
                >Поле должно быть заполнено</span> 
        <button >Подать заявку{{listItems}}</button> 
               
         
            </form>



        </div>

    </div>

</template>
<script lang="js">
import { useVuelidate } from '@vuelidate/core';
import { email, minLength, required } from '@vuelidate/validators';
import { computed, reactive } from "vue";


export default{
    data(){
        return{
            response:'',
            ID: '',
            tiketID:'',
        }
    },

    setup(){

        const state = reactive({
            v$: useVuelidate(),
            firstname:'',
            lastname:'',
            middlename:'',
            check: '',
            phone:'',
            email:'',
            message:'',
            address:'',

        })
        const rules = computed(()=>{
            return{
                firstname: { 
                    text:"" ,
                    required },
                lastname:{
                    text:"" ,
                    required },
                check:{ required },
                phone:{required,
                number: '',
                minLength: minLength(11),
                    },
                message:{
                    text:"" ,
                    required},
                email:{required, email},
                address:{
                    text:"",
                    required},
            }
        })

        const v$ = useVuelidate(rules, state)
        return{
            state,
            v$,
        }
    },


methods:{

     createPost() {
let customerE = this.state.email
let FName = this.state.firstname
let LName = this.state.lastname
let MName = this.state.middlename
let IPhone = this.state.phone
let Tmessage = this.state.message
let Aaddress = this.state.address
    this.v$.$validate()
    if(!this.v$.$error){
    $.ajax({
        url: 'https://api.domofon-37.ru/zamand/api/v1/tickets',         /* Куда отправить запрос */
        method: 'post',             /* Метод запроса (post или get) */
        dataType: 'json',          /* Тип данных в ответе (xml, json, script, html). */
        headers:{'Authorization': `Token token=6SpSTMtQcCT9v5OGKulgWEIeS-pnw6vukXCuw6r2A3kchQ3VeB8z614QT-6CJ126`},
        data:{
            title: "Заявка с сайта " + Aaddress,
            group_id: "2",
            address:Aaddress,
            customer: customerE,
            article: {
                subject: "My subject",
                body: Tmessage,
                type: "note",
                internal: false,

                    }
                    
            },
            success: function(data){
                this.ID = true
                this.tiketID = data.number
                localStorage.tiketID= this.tiketID;
              

            },



statusCode:{


    422: function(){

$.ajax({
url: 'https://api.domofon-37.ru/zamand/api/v1/users',         /* Куда отправить запрос */
method: 'post',             /* Метод запроса (post или get) */
dataType: 'json',          /* Тип данных в ответе (xml, json, script, html). */
headers:{'Authorization': `Token token=6SpSTMtQcCT9v5OGKulgWEIeS-pnw6vukXCuw6r2A3kchQ3VeB8z614QT-6CJ126`},
    data:{
        firstname: FName,
        lastname: LName,
        middlename: MName,
        email: customerE,
        phone: IPhone,
},/* Данные передаваемые в массиве */
    statusCode:{ 
        201: function(){
            $.ajax({
                url: 'https://api.domofon-37.ru/zamand/api/v1/tickets',         /* Куда отправить запрос */
                method: 'post',             /* Метод запроса (post или get) */
                dataType: 'json',          /* Тип данных в ответе (xml, json, script, html). */
                headers:{'Authorization': `Token token=6SpSTMtQcCT9v5OGKulgWEIeS-pnw6vukXCuw6r2A3kchQ3VeB8z614QT-6CJ126`},
                data:{
                    title: "Заявка с сайта " + Aaddress,
                    group_id: "2",
                    customer: customerE,
                    article: {
                        subject: "My subject",
                        body: Tmessage,
                        type: "note",
                        internal: false
                            },
                            
                    },
                    success: function(data){
                this.ID = true
                this.tiketID = data.number
                localStorage.tiketID= this.tiketID;

               
            },/* Данные передаваемые в массиве */
                    },

                    );
                    },

                    },/* функция которая будет выполнена после успешного запроса.  */
        });
                    },

            },

            });
            setTimeout(() => {
             this.$emit('nextMopen', this.ID = true)  
            }, 1000);
            

      
    }       
    

      else{

        }
        

       
},

           
                   
        



}
  
}
</script>