<template>
 <div id="preloader">
    <div style="display: contents;" >
        <img src="../../../public/log.svg" alt="" style="position: absolute;">
            <div class='loader'></div>
      </div>
    </div>
<section id="carousel">
        <div class="scroll_zone"   >
            <div class="scroll_block"
                v-touch:swipe.right="Prev"
                v-touch:swipe.left="Next"
                v-bind:class="{ myRight: myRight , myLeft: myLeft}">
                <component
                :is="componentViews[page]">
                </component>
                <div class="scroll_buttons">
                    <div class="move_left_button" @click="Prev" ></div>
                    <p class="score">{{this.page + 1}}/5</p><!-- Порверяй сколько страниц-->
                    <div class="move_right_button" @click="Next"  ></div>
                    <div class="decorative_line"></div>
                </div>
                
        </div>

        </div>
    </section>
    <section id="help">
        <h1>С чем вам помогут наши услуги?</h1>
        <div class="help_grid">
            <div class="grid_block_1 help_block">
                <h3>Безопасность придомовой территории </h3>
                <p>Даём вам возможность держать под контролем
                    территорию вокруг дома. Круглосуточный доступ к
                    камерам на придомовой территории. Возможность
                    посмотреть в реальном времени кому вы открываете
                    дверь, калитку или шлагбаум.</p>
            </div>
            <div class="grid_block_2 help_block">
                <h3>Архив записей с камер и вызовов</h3>
                <p>Если произошла непредвиденная проблема на придомовой
                    или внутридомовой территории - то доступ к архиву
                    поможет вам разобраться с подобного рода проблемами.</p>
            </div>
            <div class="grid_block_3 help_block">
                <h3>Удобство в использовании</h3>
                <p>За счёт современных технологий, которые не перегружены ненужной для пользователя информацией,
                    позволяет вам приоткрыть для себя мир будущего.
                    Осторожно, умный домофон облегчает вашу жизнь и вызывает привыкание.</p>
            </div>
            <div class="grid_block_4 help_block">
                <h3>Никого лишнего, только свои</h3>
                <p>Подделать ключ от подъезда? Взломать домофон, шлагбаум или калитку? Сразу отвечаем вам, что это решительно невозможно.
                    Мы приложили все навыки наших специалистов, чтобы система безопасности работала на вас, а не против вас.</p>
            </div>
            <div class="grid_block_5 help_block">
                <h3>Пара слов о нашей компании </h3>
                <p>Основная цель нашей компании — обеспечить безопасность имущества и жизни наших клиентов с наибольшим комфортом для вас.
                    <br>
                    Основные средства для достижения наших целей — организованные специалисты-профессионалы. При любых затруднениях смело спрашивайте
                    у них совета, ведь они всегда готовы вам помочь
                </p>
                <img src="./dom.svg" alt="">
                <h3>Будем рады видеть вас в качестве наших клиентов</h3>
            </div>
        </div>
    </section>
    <section id="services">
        <h1>Наши услуги</h1>
        <div class="services_slider">
        <div class="items">
            <div class="item active">
                <div class="slide">
                    <img src="../../assets/UslugSlider/Rectangle1_in.png" alt="">
                    <router-link  to="/Intercom"><a href="">Умный домофон</a></router-link>
                </div>
            </div>
            <div class=" item next">
                <div class="slide">
                    <img src="../../assets/UslugSlider/Rectangle2_in.png" alt="">
                    <router-link  to="/Gates"><a href="">Ворота и шлакбаумы</a></router-link>
                </div>
            </div>
            <div class="item">
                <div class="slide">
                    <img src="../../assets/UslugSlider/Rectangle4_in.png" alt="">
                    <router-link  to="/ACMS"><a href="">СКУД</a></router-link>
                </div>
                
            </div>
            <!-- <div class="item">
                <div class="slide">
                    <img src="../../assets/UslugSlider/Rectangle5_in.png" alt="">
                    <router-link  to="/Internet"><a href="">Интернет</a></router-link>
                </div>
                
            </div> -->
            <div class="item prev">
                <div class="slide">
                    <img src="../../assets/UslugSlider/Rectangle3_in.png" alt="">
                    <router-link  to="/Video"><a href="">Видеонаблюдение</a></router-link>
                </div>
            </div>

            <div class="button-container">
                <div class="button"><img src="../../assets/UslugSlider/bl3prev.svg" alt=""></div>
                <div class="button"><img src="../../assets/UslugSlider/bl3next.svg" alt=""></div>
            </div>
        </div>
    </div>
    </section>
    <section id="map">
     <Map></Map>
    </section>
    
    <footer >
<Footer></Footer>
    </footer>
    <RouterView/>

</template>

<script>

import { setTimeout } from 'core-js'
import DivSlider1 from '../../components/DivSlider/DivSlider1.vue'
import DivSlider2 from '../../components/DivSlider/DivSlider2.vue'
import DivSlider3 from '../../components/DivSlider/DivSlider3.vue'
import DivSlider4 from '../../components/DivSlider/DivSlider4.vue'
import DivSlider5 from '../../components/DivSlider/DivSlider5.vue'
// import DivSlider6 from '../../components/DivSlider/DivSlider6.vue'
import Footer from '@/components/Footer.vue'
import Map from '@/components/Map.vue'

export default{
    components:{
        DivSlider1,
        DivSlider2,
        DivSlider3,
        DivSlider4,
        DivSlider5,
        // DivSlider6
        Footer,
        Map,
    },

    data: function() {
    return {
        page: 0,
        componentViews: ['DivSlider1', 'DivSlider2','DivSlider3','DivSlider4','DivSlider5'],
        myRight: false,
        myLeft: false,
        NextTime: setTimeout(() => this.Next (), 9000),
        buttonR: false,
        buttonL: false,
    }
    },
    methods: {
        Next (){
            if (localStorage.modal == 'false') {
                clearTimeout(this.NextTime)
            this.NextTime=setTimeout(() => this.Next (), 9000)
            eval(this.NextTime)
            


if(this.page < 4 ){//  если 4 то страниц 5
        setTimeout(() => {this.page += 1 }, 250);
        }
    else{
        setTimeout(() => {this.page = 0 }, 250);
        }
        this.myRight = true

        setTimeout(() => { this.myRight = false }, 500);
            } else {
                clearTimeout(this.NextTime)
            this.NextTime=setTimeout(() => this.Next (), 9000)
            eval(this.NextTime)
            }

},
Prev(){
    if (localStorage.modal == 'false') {
        clearTimeout(this.NextTime)
        this.NextTime=setTimeout(() => this.Next (), 9000)
        eval(this.NextTime)
        if(this.page > 0 ){
        setTimeout(() => { this.page -= 1 }, 250)
    }
    else{
        setTimeout(() => { this.page = 4 }, 250)// если 4 то слайдов 5
    }
        this.myLeft= true
        setTimeout(() => { this.myLeft = false }, 500);

    } else {
        clearTimeout(this.NextTime)
            this.NextTime=setTimeout(() => this.Next (), 9000)
            eval(this.NextTime)
    }
        
    },
    
},

mounted(){

const slider = document.querySelector(".items");
const slides = document.querySelectorAll(".item");
const button = document.querySelectorAll(".button");

let current = 0;
let prev = 3;
let next = 1;

for (let i = 0; i < button.length; i++) {
    button[i].addEventListener("click", () => i == 0 ? gotoPrev() : gotoNext());

}

const gotoPrev = () => current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1);

const gotoNext = () => current < 3 ? gotoNum(current + 1) : gotoNum(0);

const gotoNum = number => {
    current = number;
    prev = current - 1;
    next = current + 1;

    for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove("active");
        slides[i].classList.remove("prev");
        slides[i].classList.remove("next");
    }

    if (next == 4) {
        next = 0;
    }

    if (prev == -1) {
        prev = 3;
    }

    slides[current].classList.add("active");
    slides[prev].classList.add("prev");
    slides[next].classList.add("next");
};

$(document).ready(function() { 
        document.body.classList.add('modal_open');
          let preloader = document.getElementById('preloader');
          
          setTimeout (function(){
            preloader.classList.add('hide-preloader');
            setInterval(function() {
                  preloader.classList.add('preloader-hidden');
                  document.body.classList.remove('modal_open');
            },3000);},2000)
        });
},

}

</script>

<style>


</style>