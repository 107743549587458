<template>
 <div id="preloader">
    <div style="display: contents;" >
        <img src="../../../public/log.svg" alt="" style="position: absolute;">
            <div class='loader'></div>
      </div>
    </div>
  <section id="ser">
    <div class="ser_zone_intercom">
      <div class="ser_bg">
        <div class="ser_block">
          <h1>Умный домофон — неоценимый помощник в вашей повседневной жизни</h1>
          <p>Система умного домофона позволяет обеспечить дополнительный уровень безопасности 
            для вас и ваших близких. Так же он помогает обеспечить 
            чистоту и порядок в подъездной части и защитить её от вандалов и хулиганов извне.</p>

        </div>
      </div>
    </div>
  </section>
  <section id="content">
    <h1>Несколько примеров того, чем вам может помочь умный домофон</h1>
    <div class="content_zone grid_help">
      
      <div class="block1 ">
        <h3>Дистанционность</h3>
        <p>Если вы потеряли ключи, то у вас всегда есть возможность дистанционно открыть дверь.
           Даже если вы не находитесь дома, а дверь необходимо открыть кому‑нибудь из ваших близких.</p>
      </div>
      <div class="block2">
        <h3>Архивирование</h3>
        <p>Интегрированная система архивирования позволяет в случае непредвиденной
           ситуации помочь разрешить её в вашу пользу и защитить ваши интересы.</p>
      </div>
      <div class="block3">
<img src="./Rectangle52.png" alt="" srcset="">
      </div>
      <div class="block4">  
        <img src="./Rectangle53.png" alt="" srcset="">
      </div>
      <div class="block5">
        <h3>Безопасность</h3>
        <p>Умный домофон даёт возможность видеть посетителя, не выходя из квартиры. Вы можете убедиться,
           кто перед вашей дверью, и принять решение, нужно ли вам открывать дверь.</p>
      </div>
      <div class="block6">
        <h3>Установка</h3>
        <p>Быстрая и простая установка с помощью наших специалистов позволит вам легко
           и непринуждённо начать пользоваться нашими услугами без лишних трат.</p>
      </div>
      <div class="block7">

<img src="./Rectangle54.png" alt="" srcset="">
      </div>

      </div>
      <div class="content_zone_two grid_help_two">
        
        <div class="block1 ">
          <h3>Безопасность</h3>
          <p>Умный домофон даёт возможность видеть посетителя, не выходя из квартиры.
             Вы можете убедиться, кто перед вашей дверью, и принять решение, нужно ли вам открывать дверь.</p>
        </div>
        <div class="block2"><img src="./mob/Rectangle1.png" alt=""></div>
        <div class="block3"><img src="./mob/Rectangle2.png" alt=""></div>
        <div class="block4">
          <h3>Дистанционность</h3>
          <p>Если вы потеряли ключи, то у вас всегда есть возможность дистанционно открыть дверь.
             Даже если вы не находитесь дома, а дверь необходимо открыть кому‑нибудь из ваших близких.</p>
        </div>
        
        <div class="block5">
          <h3>Архивирование</h3>
          <p>Интегрированная система архивирования позволяет в случае непредвиденной ситуации помочь
             разрешить её в вашу пользу и защитить ваши интересы.</p>
        </div>
        <div class="block6"><img src="./mob/Rectangle3.png" alt=""></div>
        <div class="block7"><img src="./mob/Rectangle4.png" alt=""></div>
        <div class="block8">
          <h3>Установка</h3>
          <p>Быстрая и простая установка с помощью наших специалистов позволит 
            вам легко и непринуждённо начать пользоваться нашими услугами без лишних трат.</p>
        </div>
      
      </div>

  </section>
  <footer>
<Footer></Footer>
    </footer>
  </template>

<script>
import Footer from '@/components/Footer.vue'

export default{
  components:{
    Footer,
},
  mounted(){
$(document).ready(function() { 
        document.body.classList.add('modal_open');
          let preloader = document.getElementById('preloader');
          
          setTimeout (function(){
            preloader.classList.add('hide-preloader');
            setInterval(function() {
                  preloader.classList.add('preloader-hidden');
                  document.body.classList.remove('modal_open');
            },3000);},2000)
        });
        },
}
</script>
