<template>

            <div class="foter_contacts grid_help_contact">
            <h3 class="fut_bl1">Домовой сервис</h3>
            <a href="tel:+74932343809" class="fut_bl5">+7 (4932) 34‒38‒09</a>
            <a href="tel:+74932366685" class="fut_bl3">+7 (4932) 36‒66‒85</a>
            <a href="https://yandex.ru/maps/5/ivanovo/?ll=40.967751%2C57.010139&mode=poi&poi%5Bpoint%5D=40.967812%2C57.010260&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D55362562175&source=serp_navig&z=21" target="_blank">г. Иваново, ул. Жиделева, 21, 204 офис, 2 этаж</a>
            <router-link  to="/Docs" ><a href="">Документы</a></router-link>
          </div>
          <div class="foter_contacts_2">
            <h3 class="fut_bl1">Как мы работаем</h3>
            <!-- <a>Принимаем звонки с 9:00 до 18:00 <br> c понедельника по пятницу</a>
            <a>С 24-го по 28-ое июня офис не работает.<br> Принимаем только звонки.</a> -->
            <a>Принимаем звонки с 9:00 до 18:00 <br> c понедельника по пятницу</a>
            <a>Офис работает по графику с 9:00 до <br> 14:00 c понедельника по пятницу</a>


        </div>
        <div class="footer_services grid_help_services">
            <h3>Услуги</h3>
            <router-link  to="/Video" ><a href="">Видеонаблюдение</a></router-link>
            <router-link  to="/Intercom" ><a href="">Умный домофон</a></router-link>
            <router-link  to="/Gates" ><a href="">Ворота и шлагбаумы</a></router-link>
            <router-link  to="/ACMS" ><a href="">СКУД</a></router-link>
            <!-- <router-link  to="/internet" ><a href="">Интернет</a></router-link> -->
        </div>
        <!-- <div class="foter-services_score">
          <iframe :src="FrameYandex"  width="150" height="50" frameborder="0"></iframe>
        </div> -->

</template>
<script>
import { computed } from 'vue'
export default {
data(){
    name:"Footer"
    return{

    }

},
computed:{
    FrameYandex(){
        if(localStorage.yan === "Dark"){
            return "https://yandex.ru/sprav/widget/rating-badge/55362562175?type=rating&theme=dark" 
        }
        else{
            return "https://yandex.ru/sprav/widget/rating-badge/55362562175?type=rating"
        }
    }
}
}
</script>