<template>
            <h1>Наши контакты и адреса</h1>
        <p>Офис работает для вас с 9:00 до 14:00 с понедельника по пятницу. Звонки принимаем с 9:00 до 18:00 с понедельника по пятницу.</p>
       <!--<div class="work_time_pc">
        <p><mark>С 24-ое по 28-ое июня офис не работает, принимаем только звонки.</mark> Звонки принимаем с 9:00 до 18:00 с понедельника по пятницу.</p>
       </div>
       <div class="work_time_mob">
        <p class="allert">С 24-ое по 28-ое июня офис не работает, принимаем только звонки.</p><p>Звонки принимаем с 9:00 до 18:00 с понедельника по пятницу.</p>
       </div>-->
        <div class="contact_block">
            <div class="contacts">
                <div class="contact">
                    <img src="../views/home/contact1.svg" alt="">
                    <a href="tel:+74932343809" class="fut_bl5">+7 (4932) 34‒38‒09</a>
                </div>
                <div class="contact">
                    <img src="../views/home/contact1.svg" alt="">
                    <a href="tel:+74932366685">+7 (4932) 36‒66‒85</a>
                </div>
                <div class="contact">
                    <img src="../views/home/contact2.svg" alt="">
                    <a href="https://yandex.ru/maps/5/ivanovo/?ll=40.967751%2C57.010139&mode=poi&poi%5Bpoint%5D=40.967812%2C57.010260&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D55362562175&source=serp_navig&z=21" target="_blank">г. Иваново, ул. Жиделева, 21, 204 офис, 2 этаж</a>
                </div>
                <div class="contact">
                    <img src="../views/home/contact3.svg" alt="">
                    <a href="mailto:dom@domofon-37.ru" target="_blank">dom@domofon-37.ru</a>
                </div>
                <div class="contact">
                    <img src="../views/home/contact4.svg" alt="">
                    <a href="domofon-37.ru" target="_blank">domofon-37.ru</a>
                </div>
            </div>
            <div class="map_block" id="map_block" >
            </div>
        </div>
</template>
<script>
export default{
    created(){
    let center = [57.010265, 40.967800];

function init() {

	let map = new ymaps.Map('map_block', {
		center: center,
		zoom: 17
	});
    let placemark = new ymaps.Placemark(center, {}, {
		iconLayout: 'default#image',
		iconImageSize: [40, 40],
		iconImageOffset: [-19, -44],
        });
    map.controls.remove('searchControl');
	map.geoObjects.add(placemark);

}

ymaps.ready(init);
},
}
</script>
