import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import Intercom from '../views/intercom/Intercom.vue'
import Docs from '../views/Docs/docs.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    component: Intercom,
    path: '/Intercom',
    name: 'Intercom',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/intercom/Intercom.vue')
  },
  {

    path: '/Video',
    name: 'video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/video/video.vue')
  },
  {
    path: '/Gates',
    name: 'gates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/gates/gates.vue')
  },
  {
    path: '/ACMS',
    name: 'acms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ACMS/acms.vue')
  },
  {
    path: '/About',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About/about.vue')
  },
  {
    path: '/Docs',
    name: 'docs',
    // component: Docs,
    component: () => import(/* webpackChunkName: "about" */ '../views/Docs/docs.vue')
  },
  {
    path: '/Internet',
    name: 'internet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/internet/internet.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
