<template>
<div class="feature">
                    <div class="scroll_block_text">
                        <h2>Видеонаблюдение — второй шаг на пути к светлому и безопасному будущему</h2>
                        <p>Подключаем, обслуживаем и обеспечиваем надёжную поддержку наших систем.</p>
                    </div>
                    <div class="scroll_block_features">
                        <div class="feature_block">
                            <img src="../../assets/ser1.svg" alt="">
                            <p>Индивидуальный подход при подборе цен</p>
                        </div>
                        <div class="feature_block">
                            <img src="../../assets/ser2.svg" alt="">
                            <p>Быстрый монтаж объектов</p>
                        </div>
                        <div class="feature_block">
                            <img src="../../assets/ser3.svg" alt="">
                            <p>Качественное обслуживание наших объектов</p>
                        </div>
                    </div>
                    <a href="#" class="feature_nav"><router-link  to="/Video" >Видеонаблюдение</router-link></a>
                </div>
                <div class="feature_images">
                    <div class="image_block">
                        <img src="../../assets/Sliderimg/Rectangle2_1.webp" alt="">
                        <img src="../../assets/Sliderimg/Rectangle2_2.webp" alt="">
                        <img src="../../assets/Sliderimg/Rectangle2_3.webp" alt="">
                        <img src="../../assets/Sliderimg/S2_2.png" alt="">
                    </div>

                </div>
</template>
